import React from "react";
import Link from "next/link";
import Image from "next/image";

import { LogoContainer } from "./styles";
import riseLogo from "public/assets/img/header/logo.png";

export const Logo: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  if (disabled) {
    return (
      <LogoContainer>
        <Image src={riseLogo} alt="Logo" width={300} />
        <h1>
          Elojob <br />
          <span className="rise">Rise</span>
        </h1>
      </LogoContainer>
    );
  }

  return (
    <LogoContainer>
      <Link href="/">
        <Image src={riseLogo} alt="Logo" width={300} />
        <h3>
          Elojob <br />
          <span className="rise">Rise</span>
        </h3>
      </Link>
    </LogoContainer>
  );
};
