import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";

export const LoginContainer = styled(SectionContainer)`
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url("/assets/img/landing-area/lux.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: none;

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    background-position: 75%;
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 2rem;
    border-radius: 8px;
    min-height: 300px;
    min-width: 350px;

    position: relative;
    overflow: hidden;
    border-radius: 0px;
    z-index: 3;

    @keyframes rotate {
      100% {
        transform: rotate(2turn);
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-color: #000;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient(#000, var(--tg-theme-primary)),
        linear-gradient(#000, #000), linear-gradient(#000, var(--tg-theme-primary)),
        linear-gradient(#000, #000);
      animation: rotate 10s linear infinite;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background-color: rgb(22 26 34/1);
    }

    .inputContainer {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;
      align-items: center;
      margin: 1rem 0 1rem 0;

      input {
        width: 100%;
        height: 32px;
        padding: 8px;
        background-color: #161a22;
        border: none;
        border-bottom: 2px solid var(--tg-theme-primary);
        color: #767c8e;
        font-size: 16px;

        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s,
            color 5000s ease-in-out 0s;
        }
      }
    }

    .other-options-label {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 32px;
      color: #767c8e;

      .line {
        height: 1px;
        background-color: #767c8e;
        width: 100%;
      }

      .text {
        position: absolute;
        background-color: rgb(22 26 34/1);
        padding: 12px;
        color: #767c8e;
      }
    }

    label {
      font-size: 24px;
      color: black;
    }

    input {
      padding: 4px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-self: center;
`;

export const GoogleButton = styled.button`
  cursor: pointer;
  background-color: unset;
  padding: 4px;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  gap: 8px;
  border-radius: 4px;
  background-color: rgb(26, 115, 232);

  :hover {
    background-color: rgb(23, 175, 235);
  }

  span {
    font-weight: 700;
    font-size: 14px;
  }

  svg {
    width: 32px;
    height: 32px;
    background-color: #fff;
  }
`;

export const NewAccount = styled.div`
  font-size: 14px;

  a {
    :hover {
      border-bottom: 1px solid #fff;
    }
  }

  .link {
    cursor: pointer;
    :hover {
      border-bottom: 1px solid #fff;
    }
  }
`;
