import React, { useContext, useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { FiChevronsRight } from "react-icons/fi";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import Link from "next/link";

import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

import { LOGIN_ERROR_MAP } from "./constants";
import { Actions, GoogleButton, LoginContainer, NewAccount } from "./styles";
import { CustomAcquireButton } from "../CustomAcquireButton";
import { auth } from "@/constants/firebase";
import { UserContext } from "@/contexts/userContext";
import { Logo } from "../Logo";

import { Url } from "next/dist/shared/lib/router/router";

const provider = new GoogleAuthProvider();

provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

interface LoginComponentProps {
  register: boolean;
}


export const LoginComponent: React.FC<LoginComponentProps> = ({ register }) => {
   
  const { setUser } = useContext(UserContext);
  const router = useRouter();

  const oldPage = router.query.path?.toString();
  const triedToBuyParam = router.query.triedToBuy?.toString();

  const afterLoginRoute: Url = oldPage || "/";
  const triedToBuy = triedToBuyParam === "true";

  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [isNewAccount, setIsNewAccount] = useState(() => Boolean(register));

  useEffect(() => {
    // Atualiza isNewAccount quando o valor de register muda
    setIsNewAccount(Boolean(register));
  }, [register]);

  const [isForgetAccount, setIsForgetAccount] = useState(false);

  const handleError = (error: any) => {
    const errorCode = error.code;
    const formattedMessage = LOGIN_ERROR_MAP[errorCode];

    if (!!formattedMessage) {
      toast.error(formattedMessage);
      return;
    }
    console.log(error);
    toast.error("Erro! Entre em contato via whatsapp caso persista.", error);
  };

  const handleAfterLoginRoute = () => {
    router.push(afterLoginRoute + "?&triedToBuy=" + triedToBuy);
  };

  const googleSignIn = (event: any) => {
    event.preventDefault();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;
          // The signed-in user info.
          const userData = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          setUser(userData as any);
          handleAfterLoginRoute();
        }
      })
      .catch((error) => {
        // Handle Errors here.

        const errorCode = error.code;
        const errorMessage = error.message;
        handleError(error);
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleNewEmailChange = (e: any) => {
    setNewEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
  };

  const handleLogin = (event: any) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        setUser(user as any);
        handleAfterLoginRoute();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        handleError(error);
      });
  };

  const handleNewUser = (event: any) => {
    event.preventDefault();
    createUserWithEmailAndPassword(auth, newEmail, newPassword)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setUser(user as any);
        handleAfterLoginRoute();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        handleError(error);
      });
  };

  const handleForgetPassword = (event: any) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("Link para reset enviado via email");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        handleError(error);
      });
  };

 
  if (isNewAccount) {
    return (
      <LoginContainer flexDirection="column">
        <form onSubmit={handleNewUser}>
          <Logo disabled={true} />
          <div className="inputContainer">
            <input
              type="email"
              value={newEmail}
              onChange={handleNewEmailChange}
              placeholder="E-mail"
              autoComplete="false"
            />
          </div>
          <div className="inputContainer">
            <input
              type="password"
              value={newPassword}
              placeholder="Senha"
              autoComplete="false"
              onChange={handleNewPasswordChange}
            />
          </div>
          <Actions>
            <CustomAcquireButton type="submit">Cadastrar</CustomAcquireButton>
          </Actions>
          <div className="other-options-label">
            <span className="line" />
            <span className="text">Ou</span>
          </div>
          <GoogleButton onClick={googleSignIn}>
            <FcGoogle />
            <span>Logar com o Google</span>
          </GoogleButton>
          <NewAccount>
            <span>Já é membro? </span>
            <span
              className="link"
              onClick={() => {
                setIsNewAccount(false);
                setIsForgetAccount(false);
              }}
            >
              Fazer login agora <FiChevronsRight />
            </span>
          </NewAccount>
        </form>
      </LoginContainer>
    );
  }

  if (isForgetAccount) {
    return (
      <LoginContainer flexDirection="column">
        <form onSubmit={handleForgetPassword}>
          <Logo disabled={true} />
          <div className="inputContainer">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="E-mail"
              autoComplete="false"
            />
          </div>
          <Actions>
            <CustomAcquireButton type="submit">
              Gerar link de reset de senha
            </CustomAcquireButton>
          </Actions>

          <NewAccount>
            <span>Já é membro? </span>
            <span
              className="link"
              onClick={() => {
                setIsNewAccount(false);
                setIsForgetAccount(false);
              }}
            >
              Fazer login agora <FiChevronsRight />
            </span>
          </NewAccount>
        </form>
      </LoginContainer>
    );
  }

  return (
    <LoginContainer flexDirection="column">
      <form onSubmit={handleLogin}>
        <Logo disabled={true} />
        <div className="inputContainer">
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="E-mail"
          />
        </div>
        <div className="inputContainer">
          <input
            type="password"
            value={password}
            placeholder="Senha"
            onChange={handlePasswordChange}
          />
        </div>
        <Actions>
          <CustomAcquireButton type="submit">Login</CustomAcquireButton>
        </Actions>
        <div className="other-options-label">
          <span className="line" />
          <span className="text">Ou</span>
        </div>
        <GoogleButton onClick={googleSignIn}>
          <FcGoogle />
          <span>Logar com o Google</span>
        </GoogleButton>
        <NewAccount>
          <span>Não é um membro? </span>
        
          <span className="link" onClick={() => setIsNewAccount(true)}>
            Crie sua conta agora <FiChevronsRight />
          </span>
        </NewAccount>
        <NewAccount>
          <span className="link" onClick={() => setIsForgetAccount(true)}>
            Esqueci minha senha <FiChevronsRight />
          </span>
        </NewAccount>
        <NewAccount>
          <Link href="/">Voltar a página principal</Link>
        </NewAccount>
      </form>
    </LoginContainer>
  );
};
