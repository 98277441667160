import styled from "styled-components";
import tokens from "@/tokens";

export const LogoContainer = styled.div`
  display: flex;

  img {
    width: 54px;
    height: auto;
    align-self: center;
    object-fit: contain;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    h1,
    h3 {
      text-transform: uppercase;
      color: var(--tg-heading-color);
      font-family: var(--tg-heading-font-family);
      font-size: 30px;
      margin-top: 14px;
      line-height: 0.9;

      span {
        font-weight: var(--tg-fw-bold);
        background: linear-gradient(
          to right,
          var(--tg-theme-primary),
          var(--tg-theme-primary-pallet)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  @media screen and (max-width: ${tokens.breakpoints.m}) {
    a {
      h3 {
        font-size: 20px;
      }
    }
  }
`;
