import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";

import firebaseConfig from "./config";

export const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export const db = getFirestore(app);

export const servicesCollectionRef = collection(db, "services");

export const accountsCollectionRef = collection(db, "accounts");
